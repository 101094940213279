<template>
  <div class="about">
    <CurveSection color="secondary" top="true">
      <div id="hero-section">
        <h2 class="title" data-content="About Us">About Us</h2>
        <p>
          SanThit Accelerator is Myanmar's first gender-smart accelerator
          helping nurture and encourage founders to grow impactful startups in
          Myanmar.
        </p>
        <p>
          Inclusively designed, each year SanThit's program leverages
          diagnostics, online self-paced learning, a global entrepreneurship
          curriculum, weekly consultations and then up to three rounds of
          direct, structured support to startup founders alongside increasing
          amounts of capital.
        </p>
        <!-- <img src="../assets/about-hero-img.png" alt="about hero image" /> -->
      </div>
    </CurveSection>
    <CurveSection color="white">
      <StorySection title="Santhit's Story So Far" :items="stories" />
    </CurveSection>
    <CurveSection color="primary">
      <RocketSection title="Join Our Journey" />
    </CurveSection>
    <Footer color="primary" />
  </div>
</template>

<script>
import CurveSection from "@/components/CurveSection.vue";
import StorySection from "@/components/StorySection.vue";
import RocketSection from "@/components/RocketSection.vue";
import Footer from "@/components/Footer.vue";
import stories from "@/data/stories.json";

export default {
  name: "About",
  created() {
    if (this.$store.state.isMenuOpen) this.$store.commit("toggleMenuOpen");
    document.title = this.$router.currentRoute.value.meta.title;
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  data() {
    return {
      stories,
    };
  },
  components: {
    CurveSection,
    StorySection,
    RocketSection,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/definations.scss";
@import "@/scss/about.scss";
</style>
